import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import '../static/css/skiRentalPage.css';

import { ReactComponent as CloseModal } from '../assets/closeMenu.svg';

import HeroImage from '../assets/img/ski-rental-page/ski-rental-page-hero-section-image.png';
import SnowboardImage from '../assets/img/ski-rental-page/ski-rental-page-snowboard.png';
import SkiImage from '../assets/img/ski-rental-page/ski-rental-page-ski.png';
import SafetyImage from '../assets/img/ski-rental-page/ski-rental-page-safety.png';

const RentalModalWindow = ({title, description, onClose}) => {

  return (
    <div className='container-fluid bg-dark bg-opacity-75 position-fixed top-0 start-0 d-flex justify-content-center align-items-center' style={{height: "100dvh"}}>
        <div className='col-12 col-md-10 col-lg-8 col-xxl-6 bg-color-dark-skin h-75 px-4 pt-5'>
            <div className='col-12 d-flex justify-content-end'><a className='cursor-pointer' onClick={(e) => onClose(false)}><CloseModal /></a></div>
            <h1 className='text-white text-center mb-4'>{title}</h1>
            <p className='text-white text-center'>{description}</p>
        </div>
    </div>
  )
}

const SkiRentalPage = () => {
    gsap.registerPlugin(ScrollTrigger);

    const { t, i18n } = useTranslation();

    const [rentalEquipmentInfo, setRentalEquipmentInfo] = useState([]);
    const [chosenService, setChosenService] = useState(1);
    let [isOpenModal, setIsOpenModal] = useState(false),
        [modalEquipmentTitle, setModalEquipmentTitle] = useState(''),
        [modalEquipmentDescription, setModalEquipmentDescription] = useState('');
    

    useEffect(() => {
        gsap.set('.active', {opacity: 0});
        gsap.to('.active', {opacity: 1, duration: 0.3});

        if (chosenService == 1) {
            gsap.to('.phrase-container', {top: 0, duration: 0.7, ease: "back.inOut(1.7)"});
        } else if (chosenService == 2) {
            gsap.to('.phrase-container', {top: -140, duration: 0.7, ease: "back.inOut(1.7)"});
        } else {
            gsap.to('.phrase-container', {top: -280, duration: 0.7, ease: "back.inOut(1.7)"});
        }
    }, [chosenService]);

    let changeServiceSlide = async (nextSlideNumber) => {
        gsap.to('.active', {
            opacity: 0,
            duration: 0.3,
            onComplete: () => {
                setChosenService(nextSlideNumber);
            }
        });
    }

  return (
    <div className='bg-color-dark' style={{minHeight: "100vh"}}>
        <section className='container-fluid bg-color-dark d-flex justify-content-center' style={{minHeight: "100vh"}}>
            <div className='col-11 d-flex flex-column header-offset'>
                <div className='col-12 col-xl-9 overflow-hidden position-relative' style={{height: "17vh"}}>
                    <div className='d-flex flex-column phrase-container'>
                        <h1 className='text-color-dark-skin text-center text-xl-start text-title-2 fw-bold'>Ліньки топати?<br />Ми витягнемо!</h1>
                        <h1 className='text-color-dark-skin text-center text-xl-start text-title-2 fw-bold'>Немає спорядження?<br />Ми допоможемо!</h1>
                        <h1 className='text-color-dark-skin text-center text-xl-start text-title-2 fw-bold'>Не вмієш кататись?<br />Тоді тобі до нас!</h1>
                    </div>
                </div>

                <div className='col-12 pb-5 d-flex flex-column flex-lg-row justify-content-between align-items-center align-items-xl-stretch'>
                    <div className='col-12 col-md-11 col-lg-7 col-xl-6 my-4 d-flex flex-column justify-content-between align-items-center align-items-xl-start'>
                        <div  className='col-12 d-flex flex-column justify-content-center align-items-center' style={{backgroundColor: "#1F1F1F"}}>
                            <div className='col-11 my-5 d-flex flex-row justify-content-between'>
                                <a className={'p-3 fs-6 text-dark-grey-color font-gilroy-regular border border-1 border-color-dark-grey cursor-pointer services-info-button ' + (chosenService == 1 ? 'active-button' : '')} onClick={(e) => {changeServiceSlide(1);}}>Власні Витяги</a>
                                <a className={'p-3 fs-6 text-dark-grey-color font-gilroy-regular border border-1 border-color-dark-grey cursor-pointer services-info-button ' + (chosenService == 2 ? 'active-button' : '')} onClick={(e) => {changeServiceSlide(2);}}>Оренда спорядження</a>
                                <a className={'p-3 fs-6 text-dark-grey-color font-gilroy-regular border border-1 border-color-dark-grey cursor-pointer services-info-button ' + (chosenService == 3 ? 'active-button' : '')} onClick={(e) => {changeServiceSlide(3);}}>Лижна та сноуборд школа</a>
                            </div>

                            <div className='col-11 bg-white mb-4' style={{height: "1px"}}></div>

                            {chosenService === 1 ?
                            <div className='col-12 d-flex flex-column align-items-center active' style={{height: "30vh"}}>
                                <div className='col-11 d-flex flex-row justify-content-between mb-5'>
                                    <div className='col-3 d-flex flex-column'>
                                        <p className='fs-6 text-dark-grey-color font-gilroy-regular mb-5 mt-2'>Бугельні витяги:</p>
                                        <p className='fs-6 text-dark-grey-color font-gilroy-regular'>Ціни:</p>
                                    </div>

                                    <div className='col-8 d-flex flex-row justify-content-between align-items-center'>
                                        <div className='d-flex flex-column w-auto'>
                                            <p className='fs-5 text-white font-gilroy-medium mb-5 '>360 <span className='font-gilroy-light'>м.</span></p>
                                            <p className='fs-5 text-white font-gilroy-medium m-0'>50 <span className='font-gilroy-light'>грн</span></p>
                                        </div>

                                        <div className='h-75 bg-color-dark-grey' style={{width: "0.5px"}}></div>

                                        <div className='d-flex flex-column w-auto'>
                                            <p className='fs-5 text-white font-gilroy-medium mb-5'>840 <span className='font-gilroy-light'>м.</span></p>
                                            <p className='fs-5 text-white font-gilroy-medium m-0'>80 <span className='font-gilroy-light'>грн</span></p>
                                        </div>

                                        <div className='h-75 bg-color-dark-grey' style={{width: "0.5px"}}></div>

                                        <div className='d-flex flex-column w-auto'>
                                            <p className='fs-5 text-white font-gilroy-medium mb-5'>1000 <span className='font-gilroy-light'>м.</span></p>
                                            <p className='fs-5 text-white font-gilroy-medium m-0'>100 <span className='font-gilroy-light'>грн</span></p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-11 my-3 d-flex justify-content-start align-items-start'>
                                    <p className='fs-6 fst-italic text-white font-gilroy-regular'>Для гостей готелю “ОАЗА” діє знижка 20%</p>
                                </div>
                            </div> : <p></p>}

                            {chosenService === 2 ?
                            <div className='col-12 d-flex flex-column align-items-center active' style={{height: "30vh"}}>
                                    <div className='col-11 d-flex flex-column mb-5'>
                                        <p className='fs-6 text-dark-grey-color font-gilroy-regular'>Ціни:</p>
                                        <p className='fs-5 text-white font-gilroy-medium mb-5'>від 200 до 400 грн. за комплект</p>
                                    </div>
                            </div> : <p></p>}

                            {chosenService === 3 ?
                            <div className='col-12 d-flex flex-column align-items-center active' style={{height: "30vh"}}>
                                    <div className='col-11 d-flex flex-column'>
                                        <p className='fs-4 text-white font-gilroy-medium mb-4'>Послуги досвідчених інструкторів </p>
                                        <p className='fs-6 text-dark-grey-color font-gilroy-regular'>Ціни:</p>
                                        <p className='fs-5 text-white font-gilroy-medium'>від 200 до 400 грн. за комплект</p>
                                    </div>
                            </div> : <p></p>}

                        </div>
                    </div>

                    <div className='col-10 col-md-8 col-lg-5'>
                        <img src={HeroImage} className='img-fluid' id='hero-image' />
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default SkiRentalPage